import React, {useEffect} from "react";
import "./ImagePreview.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Dims from "../../lib/Dims";

export default function ImagePreview({data, show, windowWidth, windowHeight, margins, smallMargins, onClose, onNext, onPrevious}) {

  useEffect(() => {
    if (show) {
      function handleKeydown(e) {
        switch(e.key) {
          case "Escape":
            onClose();
            break;

          case "ArrowRight":
          case "ArrowDown":
            onNext();
            break;

          case "ArrowLeft":
          case "ArrowUp":
            onPrevious();
            break;
        }
      }
      window.addEventListener("keydown", handleKeydown);
      return function() {
        window.removeEventListener("keydown", handleKeydown);
      }
    }
  }, [show, data]);

  if (!show) return (
    <div className="ImagePreview" />
  )
  const image = getImage(data.image);
  // Get the original image size.
  let originalSize = new Dims(data.image.childImageSharp.original.width, data.image.childImageSharp.original.height);
  // Max size we can display taking margins into account.
  let useMargins = windowWidth < 600 ? smallMargins : margins;
  let windowSize = new Dims(windowWidth-(useMargins*2), windowHeight-(useMargins*2));
  // Transform the image to fit inside window, but never scale up.
  let useSize = originalSize.transformToFitNoScaleUp(windowSize);

  let styles = {
    width: `${useSize.w}px`,
    height: `${useSize.h}px`
  }

  return (
    <div className="ImagePreview u-active">
      <div className="inner" style={styles}>
        <GatsbyImage image={image} alt="" />
      </div>
    </div>
  )
}

ImagePreview.defaultProps = {
  show: false,
  margins: 50,
  smallMargins: 25
}