import React, {useEffect, useState} from "react"

export default function useScrollListener() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleScroll(e) {
      setScrollY(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    setScrollY(window.scrollY);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  return scrollY;
}
