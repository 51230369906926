import React from "react";
import * as styles from "./BrickGallery.module.css";
import Dims from "../../lib/Dims";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function getLowestIndex(heights) {
  let num = Infinity;
  var colIndex = 0;
  for (var i=0; i<heights.length; i++) {
    if (heights[i] < num) {
      num = heights[i];
      colIndex = i;
    }
  }
  return colIndex;
}

function initListVal(cols, val) {
  let result = [];
  for (var i=0; i<cols; i++) {
    result.push(val);
  }
  return result;
}
function initListArray(cols) {
  let result = [];
  for (var i=0; i<cols; i++) {
    result.push([]);
  }
  return result;
}


export default function BrickGallery({data, cols, onClick}) {
  if (cols === 0) return null;
  // Value doesnt matter as it is relative.
  let colWidth = 200; 

  // Need to find out in which column each image goes.
  // We always pick the shortest column. 
  // If columns have the same size, then the first available will be picked.
  // This could be calculated offline.
  let columnHeights = initListVal(cols, 0);
  let columnIds = initListArray(cols);
  let result = data.reduce((acc, item, curIndex) => {
    let index = getLowestIndex(acc[0]);
    // add height to column.
    acc[0][index] = acc[0][index] + new Dims(item.image.childImageSharp.original.width, item.image.childImageSharp.original.height).transformW(colWidth).h;
    acc[1][index].push(curIndex);
    return acc;
  }, [columnHeights, columnIds])

  // Build it.
  let classes = `${styles.container} ${styles["grid"+cols]}`;

  let elements = result[1].map((col, curIndex) => {
    return <Column key={curIndex} index={curIndex} data={data} ids={col} onClick={onClick} />
  })

  return (
    <div className={classes}>
      <div className={styles.inner}>
        {elements}
      </div>
    </div>
  )

}

function Column({data, ids, onClick}) {
  let elements = ids.map((id) => {
    return <Item key={data[id].id} data={data[id]} id={id} onClick={onClick} />
  })

  return (
    <div className={styles.column}>
      {elements}
    </div>
  )
}

function Item({data, onClick, id}) {
  let sharp = data.image.childImageSharp;
  const image = getImage(data.image)
  let inlineStyle = {
    height: `0px`,
    paddingBottom: `${sharp.original.height/sharp.original.width * 100}%`
  }

  return (
    <div className={styles.item} style={inlineStyle} onClick={(e) => onClick(e, data, id)}>
      <GatsbyImage image={image} alt="" />
    </div>
  )
}