export function filters() {
  return [
    {
      "label": "Todas",
      "value": "",
      "url": "/"
    },
    {
      "label": "Arquitectura",
      "value": "architecture",
      "url": "/?tag=architecture"
    },
    {
      "label": "Lifestyle",
      "value": "lifestyle",
      "url": "/?tag=lifestyle"
    },
    {
      "label": "Deporte",
      "value": "sports",
      "url": "/?tag=sports"
    },
    {
      "label": "Videos",
      "value": "videos",
      "url": "/videos"
    }
  ]
}

export function getValidTagFilter(val) {
  return ["architecture", "lifestyle", "sports"].includes(val) ? val : "";
}

export function getFilterFromValue(val) {
  return filters().find(f => f.value === val) || filters()[0];
}