import React, {useEffect, useState, useRef} from "react";
import "./Filter.css";
import {cx} from "../lib/ClassSet";
import useScrollListener from "../hooks/useScrollListener";
import { Link } from "gatsby"
import { filters, getFilterFromValue } from "../lib/Filters";

export default function Filter({show, tag, onSelect}) {
  const scrollY = useScrollListener();
  const [sticky, setSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setSticky(ref.current.getBoundingClientRect().top < 25);
  }, [scrollY]);

  function handleExpand(e) {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
  }

  function handleCaptureClick(e) {
    if (expanded) setExpanded(false);
  }

  if (!show) return (
    <div className="Filter" />
  );

  let classNames = cx({
    "Filter": true,
    "u-sticky": sticky,
    "u-expanded": expanded
  })

  let filterItems = filters().map((f) => {
    return <Item key={f.value} selected={tag} url={f.url} value={f.value} title={f.label} />
  });

  return (
    <div ref={ref} className={classNames} onClick={handleCaptureClick}>
      <div className="wrapper">
        <div className="inner">
          <div className="title" onClick={handleExpand}>
            <div>Filtro: {getFilterFromValue(tag).label}</div>
            <div className="nextIcon">
              <svg width="100%" height="100%" viewBox="0 0 18 18" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g transform="translate(3.000000, 5.000000)" stroke="#FFFFFF">
                          <line id="line1" x1="0.25" y1="4" x2="11.75" y2="4" strokeLinecap="square"></line>
                          <polyline id="line2" strokeLinejoin="round" points="8 0 12 4 8 8"></polyline>
                      </g>
                  </g>
              </svg>
            </div>
            <div className="closeIcon">
              <svg width="100%" height="100%" viewBox="0 0 18 18" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <path id="path" d="M5.36363636,5.36363636 L12.6363636,12.6363636 M12.6363636,5.36363636 L5.36363636,12.6363636" stroke="#FFFFFF" strokeLinecap="square"></path>
                      <circle id="circle" stroke="#DDDDDD" cx="9" cy="9" r="8"></circle>
                  </g>
              </svg>
            </div>
          </div>
          <div className="options">
            {filterItems}
          </div>
        </div>
      </div>
    </div>
  )
}

function Item(props) {
  function isActive() {
    return props.value === props.selected ? { className: "u-selected" } : {};
  }

  return (
    <Link to={props.url} getProps={isActive} {...props} title={props.title}>{props.title}</Link>
  )
}