import React from "react";
import Logo1 from "../images/monovision-logo.svg";
import "./MasterFooter.css";
import InstagramLogo from "../images/instagram.svg";

export default function MasterFooter(props) {
  return (
    <div className="MasterFooter">
      <div className="logotype">
        <img src={Logo1} alt="Monovision logotype"/>
      </div>
      <div className="social">
        <a href="https://www.instagram.com/living_andalucia" title="Follow me on Instagram">
          <img src={InstagramLogo} alt="Follow me on Instagram" />
        </a>
      </div>
      <div className="copyright">
        © {new Date().getFullYear()}, monovision.studio
      </div>      
    </div> 
  )
}