import React from "react";
import LogoSplit from "../images/logotype.svg";
import "./MasterHeader.css";

export default function MasterHeader(props) {
  return (
    <div className="MasterHeader">
      <div className="logo-split">
        <a href="/" title="Monovision Studio">
          <img src={LogoSplit} alt="Monovision logotype"/>
        </a>
      </div>
      <div className="services">
        Fotografía • Drone • Video
      </div>
      <div className="contact-details">
        <a href="mailto:ignacio@monovision.studio" title="Email">ignacio@monovision.studio</a>
        <strong className="name">Ignacio de Salas Zubiaur</strong><br/>
      </div>      
    </div> 
  )
}