import React, {useState, useEffect} from "react"
import MasterHeader from "../components/MasterHeader"
import MasterFooter from "../components/MasterFooter"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BrickGallery from "../components/brick-gallery/BrickGallery";
import ImagePreview from "../components/image-preview/ImagePreview";
import Controls from "../components/image-preview/Controls";
import Filter from "../components/Filter";
import { getValidTagFilter } from "../lib/Filters";
import { graphql } from 'gatsby';


function getPreferredColumns(visualWidth) {
  if (visualWidth < 400) return 1;
  if (visualWidth < 800) return 2;
  if (visualWidth < 1400) return 3;
  return 4;
}

function filterByTag(data, tag) {
  if (tag === "") return data;
  return data.filter(node => {
    return node.tags.some((t) => t === tag);
  })
}

const IndexPage = ({data, location}) => {
  const [columns, setColumns] = useState(0);
  const [windowSize, setWindowSize] = useState({w: 0, h: 0});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    function handleResize() {
      setColumns(getPreferredColumns(window.innerWidth));
      setWindowSize({w: window.innerWidth, h: window.innerHeight});
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return function() {
      window.removeEventListener("resize", handleResize);
    }
  }, []);
  
  useEffect(() => {
    if (preview === true) {
      document.scrollingElement.style.overflow = "hidden";
    }
    
    return function() {
      document.scrollingElement.style.overflow = null;
    }
  }, [preview]);
  
  let params = new URLSearchParams(location.search);
  let tag = params.get("tag") || "";
  let tagFilter = getValidTagFilter(tag);

  let filteredImages = filterByTag(data.allGalleryJson.nodes, tagFilter);

  function handleClickImage(e, info, index) {
    setSelectedIndex(index);
    setPreview(true);
  }

  function handleClose() {
    setPreview(false);
  }

  function handlePrevious() {
    let newIndex = selectedIndex - 1;
    if (newIndex < 0) {
      newIndex = filteredImages.length - 1;
    }
    setSelectedIndex(newIndex);
  }

  function handleNext() {
    let newIndex = selectedIndex + 1;
    if (newIndex >= filteredImages.length) {
      newIndex = 0;
    }
    setSelectedIndex(newIndex);
  }

  let selectedImage = filteredImages[selectedIndex];
  return (
    <Layout>
      <Seo title="Home" />
      <MasterHeader />
      <ImagePreview 
        data={selectedImage} 
        show={preview} 
        onClose={handleClose} 
        onNext={handleNext}
        onPrevious={handlePrevious}
        windowWidth={windowSize.w} 
        windowHeight={windowSize.h} />
      <Controls 
        show={preview} 
        onClose={handleClose} 
        onNext={handleNext}
        onPrevious={handlePrevious}
        />
      <Filter tag={tagFilter} show={!preview} />
      <BrickGallery data={filteredImages} cols={columns} onClick={handleClickImage} />
      <MasterFooter />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allGalleryJson {
      nodes {
        id
        tags
        image {
          childImageSharp {
            original {
              width
              height
              src
            }
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

export default IndexPage;
