import React from "react";
import "./Controls.css";

export default function Controls({show, onClose, onNext, onPrevious}) {
  if (!show) return (
    <div className="ImagePreviewControls" />
  );

  return (
    <div className="ImagePreviewControls u-active">
      <div className="icon logo" />
      <div className="icon next" onClick={onNext}>
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(3.000000, 5.000000)" stroke="#FFFFFF">
                    <line x1="0.25" y1="4" x2="11.75" y2="4" strokeLinecap="square"></line>
                    <polyline strokeLinejoin="round" points="8 0 12 4 8 8"></polyline>
                </g>
            </g>
        </svg>
      </div>
      <div className="icon previous" onClick={onPrevious}>
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) translate(3.000000, 5.000000)" stroke="#FFFFFF">
                    <line x1="0.25" y1="4" x2="11.75" y2="4" id="Line" strokeLinecap="square"></line>
                    <polyline id="Path" strokeLinejoin="round" points="8 0 12 4 8 8"></polyline>
                </g>
            </g>
        </svg>      
      </div>
      <div className="icon close" onClick={onClose}>
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M5.36363636,5.36363636 L12.6363636,12.6363636 M12.6363636,5.36363636 L5.36363636,12.6363636" stroke="#FFFFFF" strokeLinecap="square"></path>
                <circle stroke="#DDDDDD" cx="9" cy="9" r="8"></circle>
            </g>
        </svg>
      </div>
    </div>
  )
}